import React from "react";
import Imgix from "react-imgix";
import { navigate } from "gatsby";
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import "lazysizes/plugins/blur-up/ls.blur-up";
import DefaultButton from "../Buttons/DefaultButton";

const GermanRedCross =
  "https://imgix.cosmicjs.com/8195af50-9cf3-11ed-93ee-cb9a2cd68754-RedCross.png";
const Unicef =
  "https://imgix.cosmicjs.com/89a1ed30-9cf3-11ed-93ee-cb9a2cd68754-Unicef.png";
const CalgaryHumane =
  "https://imgix.cosmicjs.com/8e696050-9cf3-11ed-93ee-cb9a2cd68754-CalgaryHumaneSociety.png";
const IslandKidsCancer =
  "https://imgix.cosmicjs.com/92d9b2c0-9cf3-11ed-93ee-cb9a2cd68754-IslandKidsCancerAssociation.png";
const AmericanBrainTumor =
  "https://imgix.cosmicjs.com/97107ea0-9cf3-11ed-93ee-cb9a2cd68754-AmericanBrainTumorAssociation.png";

const CharitiesInvolved = () => {
  const handleTryBidddy = () => {
    if (typeof window !== "undefined") {
      window.open("https://www.jotform.com/form/230056389150049", "_blank");
    }
  };

  const handleLearnAboutEvents = () => {
    navigate("/learn/how-it-works");
  };

  return (
    <section
      className="w-full py-20 md:pt-32 md:pb-32"
      style={{ background: "#fff" }}
    >
      <div className="max-w-6xl w-full mx-auto px-5 flex flex-col justify-between gap-2">
        <div className="w-full flex flex-row flex-wrap justify-between items-center gap-4 md:gap-6">
          <Imgix
            src={`${GermanRedCross}?auto=format`}
            height={53}
            width={138}
            htmlAttributes={{
              alt: "German Red Cross",
              src: `${GermanRedCross}?blur=500&px=4&auto=format`,
            }}
            attributeConfig={{
              src: "data-src",
              srcSet: "data-srcset",
              sizes: "data-sizes",
            }}
            className="lazyload blur-up"
          />
          <Imgix
            src={`${Unicef}?auto=format`}
            height={50}
            width={206}
            htmlAttributes={{
              alt: "Unicef",
              src: `${Unicef}?blur=500&px=4&auto=format`,
            }}
            attributeConfig={{
              src: "data-src",
              srcSet: "data-srcset",
              sizes: "data-sizes",
            }}
            className="lazyload blur-up"
          />
          <Imgix
            src={`${CalgaryHumane}?auto=format`}
            height={70}
            width={120}
            htmlAttributes={{
              alt: "Calgary Humane Society",
              src: `${CalgaryHumane}?blur=500&px=4&auto=format`,
            }}
            attributeConfig={{
              src: "data-src",
              srcSet: "data-srcset",
              sizes: "data-sizes",
            }}
            className="lazyload blur-up"
          />
          <Imgix
            src={`${IslandKidsCancer}?auto=format`}
            height={75}
            width={183}
            htmlAttributes={{
              alt: "Island Kids Cancer Association",
              src: `${IslandKidsCancer}?blur=500&px=4&auto=format`,
            }}
            attributeConfig={{
              src: "data-src",
              srcSet: "data-srcset",
              sizes: "data-sizes",
            }}
            className="lazyload blur-up"
          />
          <Imgix
            src={`${AmericanBrainTumor}?auto=format`}
            height={68}
            width={136}
            htmlAttributes={{
              alt: "American Brain Tumor Associations",
              src: `${AmericanBrainTumor}?blur=500&px=4&auto=format`,
            }}
            attributeConfig={{
              src: "data-src",
              srcSet: "data-srcset",
              sizes: "data-sizes",
            }}
            className="lazyload blur-up"
          />
        </div>
        <h3 className="text-bidddyPink text-3xl mt-6">
          Good company. Great causes. Amazing results.
        </h3>
        <div className="flex flex-col gap-2 mt-4 sm:items-center items-start md:items-start">
          <DefaultButton
            extraClasses="px-4"
            onClick={() => navigate("/case-studies")}
            title="View Case Studies"
          />
        </div>
      </div>
    </section>
  );
};

export default CharitiesInvolved;
