import { Link, navigate } from "gatsby";
import React from "react";
import Imgix from "react-imgix";
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import "lazysizes/plugins/blur-up/ls.blur-up";
import DefaultButton from "../Buttons/DefaultButton";
import BidddyLogo from "../Images/BidddyLogo";
import styles from "./charities.module.scss";
import Tidbits from "../Home/internal/Tidbits";

const MobileTop =
  "https://imgix.cosmicjs.com/cb54b9a0-c689-11ed-b620-593a056e791b-Charities.png";

const CharitiesTop = () => (
  <section className="w-full pt-20 lg:pt-32" style={{ background: "#F5F5F5" }}>
    <div className="max-w-6xl w-full mx-auto px-5 flex flex-col justify-between gap-2 mb-8">
      <div className={styles.charityBackground}>
        <Imgix
          src={`${MobileTop}?auto=format`}
          height={696}
          width={696}
          htmlAttributes={{
            alt: "Bidddy Charities",
            src: `${MobileTop}?blur=500&px=4&auto=format`,
          }}
          attributeConfig={{
            src: "data-src",
            srcSet: "data-srcset",
            sizes: "data-sizes",
          }}
          className="lazyload blur-up lg:hidden block"
        />
        <BidddyLogo className="text-bidddyPink" />
        <h1 className="my-4 font-inter lg:text-6xl text-4xl max-w-md font-bold">
          Raise more funds with auctions on Instagram
        </h1>
        <h2 className="font-cubano text-2xl text-bidddyGray">
          Automated Auctions
          <br />
          Custom Auction Webpage
          <br />
          One Click Bidding
          <br />
          and so much MORE!
        </h2>
        <p className="max-w-sm text-sm mt-4">
          Bidddy helps individuals and organizations run automated social
          auctions that increase awareness, drive engagement and take
          fundraising to a whole new level.
        </p>
        <h3 className="font-inter font-bold text-2xl text-bidddyPink mt-8 mb-4">
          Ready to get started?{" "}
        </h3>
        <div className="flex flex-col w-48">
          <DefaultButton
            title="Contact Us"
            onClick={() => navigate("/contact-us/")}
          />
          <Link
            to="/"
            className="text-bidddyPink font-inter font-bold text-sm mx-auto mt-4 w-full text-center"
          >
            Learn More
          </Link>
        </div>
      </div>
      <div className="flex justify-end">
        <div className="mt-5 lg:mt-0 lg:w-1/2 justify-start flex flex-col">
          <h5 className="italic text-bidddyPink font-inter text-3xl mb-1">
            “We didn’t realize our potential on social until using Bidddy”
          </h5>
          <p>- Hope Milloy, Island Kids Cancer </p>
        </div>
      </div>
    </div>
    <Tidbits background="bg-inherit" />
  </section>
);

export default CharitiesTop;
